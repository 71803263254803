/* eslint-disable */
import 'babel-polyfill'
import React from 'react'
import PropTypes from 'prop-types'
import { hashHistory } from 'react-router'
import marketStyles from '../../../styles/markets.scss'
import pureGridStyles from '../../../styles/pure-grid.scss'
import snapshotStyles from '../../../styles/snapshot.V2/snapshot.scss'
import TopHeader from './SubComponents/TopHeader'
import OverviewStock from './SubComponents/OverviewStock'
import SnapshotOptionsContainer from './SubComponents/SnapshotOptionsContainer'
import OverviewMF from './SubComponents/OverviewMF'
import OverviewETF from './SubComponents/OverviewETF'
import News from './SubComponents/News/NewsContainer'
import NewsMobile from './SubComponents/News/NewsMobileContainer'
import OverviewIndices from './SubComponents/OverviewIndices'
import PerformanceSummaryContainer from './SubComponents/PerformanceSummaryContainer'
import { Tabs, PersistentData } from 'stifel-intyce-pattern-library'
import { ISSUE_TYPES, ISSUE_TYPES_MAP, WATCHLISTS_SELECTED_CONSTANTS, SNAPSHOT_WSODISSUE_CONSTANTS, SNAPSHOT_SYMBOL_CONSTANTS, SNAPSHOT_ACTIVETAB_CONSTANTS, SNAPSHOT_NEWS_CONSTANTS} from '../../../utils/appConstants'
import { getUrlParameterValueByName } from '../../../utils/urlMethods'
import { UserTypeIndicator } from 'stifel-intyce-pattern-library'
import Notification from '../../watchlist/SubComponents/Notification'
import { updateParentHeightForMobileView, isMobileView, GetLocalStorageIfAvailable, SetSpecificKeysIntoLocalStorage, ClearSpecificKeysFromLocalStorage, CheckIfItsAnIpad } from '../common.v2/CommonMethods'
import commonStyles from '../../../styles/common.v2/common.scss'

export default class Snapshot extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMobileView: false,
      backButtonLevel: null,
      notification: {
        show: false,
        message: null
      },
      activeTab: this.setActiveTab()
    }
    
    this.props.setFetchingDataFlag({ fetching: true });
    this.getTabNamePerAssestClass = this.getTabNamePerAssestClass.bind(this)
    this.handleResize = this.handleResize.bind(this)
    this.renderOverviewTabData = this.renderOverviewTabData.bind(this)
    this.getParamWsodIssue = this.getParamWsodIssue.bind(this)
    this.getParamSymbol = this.getParamSymbol.bind(this)
    this.setIsMobileView = this.setIsMobileView.bind(this)
    this.goBackHandler = this.goBackHandler.bind(this)
    this.backButtonLevel = this.backButtonLevel.bind(this)
    this.notificationHandler = this.notificationHandler.bind(this)
    this.getPage = this.getPage.bind(this)
    let symbol = this.getParamSymbol()
    let page = this.getPage()
    if(symbol && ((GetLocalStorageIfAvailable(page, SNAPSHOT_SYMBOL_CONSTANTS)!==symbol))) {
      SetSpecificKeysIntoLocalStorage(page, {
        [SNAPSHOT_WSODISSUE_CONSTANTS]:this.getParamWsodIssue(),
        [SNAPSHOT_SYMBOL_CONSTANTS]:symbol
      })
    }
    //Clear snapshot session
    sessionStorage.removeItem('snapshot-v2-selected-tab')
  }

  componentDidMount() {
    this.props.requestSnapshotV2Data(this.getParamWsodIssue(), this.getParamSymbol());
    window.addEventListener('resize', this.handleResize)
    this.setIsMobileView()

    let self = this
    window.setTimeout(function() {
      self.handleResize()
    }, 1000)
    updateParentHeightForMobileView()
  }

  componentDidUpdate (prevProps) {
    updateParentHeightForMobileView()
  }

  componentWillReceiveProps() {
    this.handleResize()
  }
  
  notificationHandler (notification) {
    this.setState({ notification: notification })
  }

  getParamWsodIssue() {
    return this.props.wsodIssue ? this.props.wsodIssue : this.props.params.wsodissue;
  }
  getParamSymbol() {
    return this.props.symbol ? this.props.symbol : this.props.params.symbol;
  }

  setIsMobileView() {
    this.setState({ isMobileView: isMobileView() })
  }

  handleResize() {
    this.setIsMobileView()
    const snapshotPopUpCotentContainer = document.getElementById('snapshotPopUpCotentContainer');

    //add to watchlist button in modal popup
    const btnAddToWatchList = document.getElementById('btnAddToWatchList')
    if (snapshotPopUpCotentContainer != null &&
      btnAddToWatchList != null &&
      window.innerWidth < 768) {
      btnAddToWatchList.style.cssFloat = "none";
    }

    const tabContainer = document.getElementById('tabContainer')
    if (tabContainer != null && snapshotPopUpCotentContainer != null) {
      tabContainer.style.height = snapshotPopUpCotentContainer.offsetHeight - 186 + 'px'
      if(window.innerWidth>999){
        tabContainer.style.height = snapshotPopUpCotentContainer.offsetHeight - 186 + 'px'
      }
      else{
        tabContainer.style.height = snapshotPopUpCotentContainer.offsetHeight - 236 + 'px'
      }
    }
  };

  getTabNamePerAssestClass() {
    let tabName

    switch (this.props.securityType) {
      case ISSUE_TYPES.Stock:
      case ISSUE_TYPES.Indices:
        tabName = 'News'
        break
      case ISSUE_TYPES.Etf:
      case ISSUE_TYPES.MutualFund:
        tabName = 'Top Holdings News'
        break
      default:
        tabName = undefined
    }

    return tabName
  }

  renderOverviewTabData() {
    switch (this.props.securityType) {
      case ISSUE_TYPES.MutualFund:
        return <OverviewMF
          handleResize={this.handleResize}
          fetching
          wsodIssue={this.getParamWsodIssue()}
          dateAsOf={this.props.quoteData_Platform.nav.date || null}>
          </OverviewMF>
        break
      case ISSUE_TYPES.Etf:
        return <OverviewETF
              isMobileView={this.state.isMobileView}
              handleResize={this.handleResize}
              fetching symbol={this.props.symbol ? this.props.symbol : this.props.params.symbol}
              wsodIssue={this.getParamWsodIssue()}
              dateAsOf={this.props.quoteData_Platform.lastTrade.date}> </OverviewETF>
        break
      case ISSUE_TYPES.Indices:
        return <OverviewIndices
              isMobileView={this.state.isMobileView}
              handleResize={this.handleResize}
              symbol={this.props.symbol ? this.props.symbol : this.props.params.symbol}
              wsodIssue={this.getParamWsodIssue()}
              dateAsOf={this.props.quoteData_Platform.lastTrade.date} />
        break
      default:
        return <OverviewStock
              isMobileView={this.state.isMobileView}
              handleResize={this.handleResize}
              fetching symbol={this.props.symbol ? this.props.symbol : this.props.params.symbol}
              wsodIssue={this.getParamWsodIssue()}
              dateAsOf={this.props.quoteData_Platform.lastTrade.date} />
    }
  }

  goBackHandler () {
    ClearSpecificKeysFromLocalStorage(this.getPage(), [
      SNAPSHOT_WSODISSUE_CONSTANTS,
      SNAPSHOT_SYMBOL_CONSTANTS,
      SNAPSHOT_ACTIVETAB_CONSTANTS,
      SNAPSHOT_NEWS_CONSTANTS      
    ])
    let returnUrl = getUrlParameterValueByName('returnUrl')
    if (returnUrl) {
      hashHistory.push(returnUrl)
    }
  }

  backButtonLevel () {
    let returnUrl = getUrlParameterValueByName('returnUrl')
    let page = this.getPage()
    if(returnUrl)
    {
      let watchlstTab = GetLocalStorageIfAvailable(page, WATCHLISTS_SELECTED_CONSTANTS)
      if(watchlstTab && watchlstTab.id && watchlstTab.id.length > 0)
      {
        return 'Back to Watchlist'
      }
    }
    if (returnUrl) {
      if(returnUrl === '/Navigator/Watchlists') {
        return 'Back to Watchlist Overview'
      } else if (returnUrl === '/Navigator/Markets/V2') {
        return 'Back to Markets & News'
      } else if (returnUrl.indexOf('/Navigator/Watchlist/') === 0) {
        return 'Back to Watchlist'
      }
    }
  }

  setActiveTab()
  {
    let page = this.getPage()
    return sessionStorage.getItem('snapshot-v2-selected-tab') || GetLocalStorageIfAvailable(page, SNAPSHOT_ACTIVETAB_CONSTANTS)
  }
  onTabClick(tab, page)
  {
    SetSpecificKeysIntoLocalStorage(page, {
      [SNAPSHOT_ACTIVETAB_CONSTANTS]:tab
    })
  }
  getPage() {
    return this.props.page ? this.props.page : (this.props.location && this.props.location.query && this.props.location.query.page) ? this.props.location.query.page : ''
  }
  render() {
    if (this.props.fetching) {
      return (
        <div className={`${commonStyles['loader']}`}>
          <div className={`${commonStyles['rollingloader']}`}></div>
        </div>
      )
    }

    if (this.props.isErrorOccurred) {
      return (
        <div className={`${snapshotStyles['error-container']}`}>
          <div>
            Oops! There was an error processing your request.<br />
            <div className={`${snapshotStyles['error-info']}`}>
              (Error Code: {this.props.errorInfo.code}, Message: {this.props.errorInfo.message})
            </div>
          </div>
        </div>
      )
    }

    if (!ISSUE_TYPES_MAP[this.props.quoteData_Platform.instrumentIssueType]) {
      return (
        <div className={`${snapshotStyles['error-container']}`}>
          <div>
            We are sorry, but we are unable to generate a company overview for this symbol.<br />
          </div>
        </div>
      )
    }

    return (
      <div className={`${snapshotStyles['snapshot-content-container']}`}>
        <Notification
          show={this.state.notification.show}
          message={this.state.notification.message}
          notificationHandler={this.notificationHandler} />

        {
         (window.innerWidth < 1000  ||(!document.getElementById('snapshotPopUpCotentContainer') && CheckIfItsAnIpad())) && <div className={`${snapshotStyles['back-button']} ${snapshotStyles['pure-hidden-lg']} ${snapshotStyles['pure-hidden-xl']}`}>
            <a data-el-identifier='snapshot_back' onClick={this.goBackHandler} title={this.backButtonLevel()}>
              <span className={`${snapshotStyles['arrow']}`} />
              <span>{this.backButtonLevel()}</span>
              <span className={`${commonStyles['sr-only']}`}>Go to Previous Page</span>
            </a>
          </div>
        }

        <section className={`${snapshotStyles['top-header-container']}`}>
        {this.props.securityType === ISSUE_TYPES.MutualFund &&
            <TopHeader
              symbol={this.props.symbol ? this.props.symbol : this.props.params.symbol}
              isMobileView={this.state.isMobileView}
              quoteData={this.props.quoteData}
              wsodIssue={this.getParamWsodIssue()}
              notificationHandler={this.notificationHandler}
              date={this.props.quoteData_Platform.nav.date || null} />
          }
          {this.props.securityType !== ISSUE_TYPES.MutualFund &&
            <TopHeader
                symbol={this.props.symbol ? this.props.symbol : this.props.params.symbol}
                isMobileView={this.state.isMobileView}
                quoteData={this.props.quoteData}
                wsodIssue={this.getParamWsodIssue()}
                notificationHandler={this.notificationHandler}
                date={this.props.quoteData_Platform.lastTrade.date} />
          }
        </section>

        {
          !this.state.isMobileView &&
          <section className={`${snapshotStyles['persistant-data-container']}`}>
            <PersistentData
              securityType={this.props.securityType}
              isMobileView={this.state.isMobileView}
              lastPrice={this.props.quoteData_Platform.lastTrade.last}
              mfNav={this.props.quoteData_Platform.nav && this.props.quoteData_Platform.nav.last}
              todaysPrice={this.props.quoteData.change}
              todaysPriceChange={this.props.quoteData.changePct}
              monthChange={this.props.quoteData_Platform.changeAmount.oneMonth}
              monthChangePercent={this.props.quoteData_Platform.changePercent.oneMonth}
              volume={this.props.quoteData_Platform.volume.last}
              volumeComment={this.props.quoteData_Platform.volume.pace}
              rangeFrom={this.props.quoteData_Platform.price52Week.low}
              rangeTo={this.props.quoteData_Platform.price52Week.high}
              currentPrice={this.props.quoteData_Platform.lastTrade.last}
              isMarketOpen={this.props.isMarketOpen}>
            </PersistentData>
          </section>
        }

        <section className={`${snapshotStyles['tabs-container']}`}>
          {
            <Tabs activeTab={this.state.activeTab} onClick={this.onTabClick} page={this.getPage()}>

            <div label="Overview" tabIndex="0">
                <div className={`${pureGridStyles['pure-g']} ${snapshotStyles['tab']}`}>
                  <div className={`${snapshotStyles['tab-content-container']}`} id="tabContainer">
                    {
                      this.state.isMobileView &&
                      <div className={`${snapshotStyles['persistant-data-container']}`}>
                        <PersistentData
                          securityType={this.props.securityType}
                          isMobileView={this.state.isMobileView}
                          lastPrice={this.props.quoteData_Platform.lastTrade.last}
                          mfNav={this.props.quoteData_Platform.nav && this.props.quoteData_Platform.nav.last}
                          todaysPrice={this.props.quoteData.change}
                          todaysPriceChange={this.props.quoteData.changePct}
                          monthChange={this.props.quoteData_Platform.changeAmount.oneMonth}
                          monthChangePercent={this.props.quoteData_Platform.changePercent.oneMonth}
                          volume={this.props.quoteData_Platform.volume.last}
                          volumeComment={this.props.quoteData_Platform.volume.pace}
                          rangeFrom={this.props.quoteData_Platform.price52Week.low}
                          rangeTo={this.props.quoteData_Platform.price52Week.high}
                          currentPrice={this.props.quoteData_Platform.lastTrade.last}
                          isMarketOpen={this.props.isMarketOpen}>
                        </PersistentData>
                      </div>
                    }
                    {
                      this.renderOverviewTabData()
                    }
                  </div>
                </div>
              </div>

              <div label="Performance" tabIndex="0">
                <div className={`${pureGridStyles['pure-g']} ${snapshotStyles['tab']}`}>
                  <div id="tabContainer" className={`${snapshotStyles['tab-content-container']}`}>
                    <PerformanceSummaryContainer
                      lastPrice={this.props.quoteData_Platform.lastTrade.last}
                      todaysPriceChange={this.props.quoteData.change}
                      todaysPricePercentChange={this.props.quoteData.changePct}
                      open={this.props.quoteData.open}
                      openGap={this.props.quoteData.openGap}
                      previousClose={this.props.quoteData_Platform.lastTrade.close}
                      tradingVolume={this.props.quoteData_Platform.volume.last}
                      avgVolume10D={this.props.quoteData_Platform.volume.average10Day}
                      sharesOutstanding={this.props.quoteData_Platform.sharesOutstanding}
                      marketCap={this.props.quoteData_Platform.marketCap}
                      tradingActivity={this.props.quoteData_Platform.volume.pace}
                      earningsPrv12M={this.props.quoteData.earningsPrv12M}
                      earningsEstimate12M={this.props.quoteData.earningsEstimate12M}
                      ratioPE={this.props.quoteData.ratioPE}
                      change3M={this.props.quoteData_Platform.changeAmount.threeMonth}
                      changePct3M={this.props.quoteData_Platform.changePercent.threeMonth}
                      change6M={this.props.quoteData_Platform.changeAmount.sixMonth}
                      changePct6M={this.props.quoteData_Platform.changePercent.sixMonth}
                      change1Y={this.props.quoteData_Platform.changeAmount.oneYear}
                      changePct1Y={this.props.quoteData_Platform.changePercent.oneYear}
                      change2Y={this.props.quoteData_Platform.changeAmount.twoYear}
                      changePct2Y={this.props.quoteData_Platform.changePercent.twoYear}
                      nav={this.props.quoteData_Platform.nav && this.props.quoteData_Platform.nav.last}
                      navETF={this.props.quoteData_Platform.nav && this.props.quoteData_Platform.nav.close}
                      isMarketOpen={this.props.isMarketOpen}
                      securityType={this.props.securityType}
                      wsodIssue={this.getParamWsodIssue()}
                      handleResize={this.handleResize}
                      revenue={this.props.quoteData.revenue}
                      isMobileView={this.state.isMobileView}
                    />
                  </div>
                </div>
              </div>

              <div label={this.getTabNamePerAssestClass()} tabIndex="0">
                <div className={`${pureGridStyles['pure-g']} ${snapshotStyles['tab']}`}>
                <div className={`${snapshotStyles['tab-content-container']} ${snapshotStyles['news-parent-container']}`} id="tabContainer">                    
                    {
                      this.state.isMobileView &&
                      <NewsMobile
                        securityType={this.props.securityType}
                        wsodIssue={this.getParamWsodIssue().toString()}
                        page={this.getPage()}>
                      </NewsMobile>
                    }
                    {
                      !this.state.isMobileView &&
                      <News
                        isMobileView={this.state.isMobileView}
                        securityType={this.props.securityType}
                        wsodIssue={this.getParamWsodIssue().toString()}
                        handleResize={this.handleResize}
                        page={this.getPage()}>
                      </News>
                    }
                  </div>
                </div>
              </div>

              {
                (this.props.securityType === ISSUE_TYPES.Stock || this.props.securityType === ISSUE_TYPES.Etf)  &&
                <div label="Options" tabIndex="0">
                  <div className={`${pureGridStyles['pure-g']} ${snapshotStyles['tab']}`}>
                    <div className={`${snapshotStyles['option-tab-content-container']}`}>
                      <SnapshotOptionsContainer
                        lastPrice={this.props.quoteData_Platform.lastTrade.last}
                        isMobileView={this.state.isMobileView}
                        wsodIssue={this.getParamWsodIssue()} />
                    </div>
                  </div>
                </div>
              }
            </Tabs>
          }
        </section>        
        <div className={`${snapshotStyles['user-type-indicator']}`}>
          <UserTypeIndicator userType='Navigator' />
        </div>
      </div>
    )
  };
};

Snapshot.propTypes = {
  symbol: PropTypes.string,
  wsodIssue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isMarketOpen: PropTypes.bool.isRequired,
  page: PropTypes.string,
  location: PropTypes.object
};

