import TagManager from 'react-gtm-module'
import {
  GTM_ID
} from './appConstants'

export const initiaizeGoogleAnalytics = () => {
  // GTM args for Investor (Tracker) and Advisor (Navigator)
  let isInvestorSite = window.location.href.toLowerCase().indexOf('navigator') === -1
  let tagManagerArgs = {
    gtmId: isInvestorSite ? GTM_ID.Investor : GTM_ID.Advisor,
    auth: isInvestorSite ? window.MD.GTM_AUTH_INVESTOR : window.MD.GTM_AUTH_ADVISOR,
    preview: window.MD.GTM_PREVIEW || ''
  }

  // Initialize Google Analytics
  TagManager.initialize(tagManagerArgs)
}
